.faqContainer {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Open Sans', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.title {
  font-size: 2rem;
  font-weight: 400;
  color: #333;
  text-align: left;
  margin-bottom: 40px;
}

.content {
  display: flex;
  flex-direction: column;
}

.faqList {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.faqItem {
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 24px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.faqItem:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.question {
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 12px;
}

.answer {
  font-size: 1.1rem;
  color: #4b4b4b;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .faqContainer {
    padding: 8px;
  }

  .title {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 24px;
  }

  .faqItem {
    padding: 16px;
  }

  .question {
    font-size: 1.1rem;
  }

  .answer {
    font-size: 1rem;
  }
}