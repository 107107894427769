.notFoundContainer {
  text-align: center;
  margin-top: 100px;
}

.title {
  font-size: 48px;
  color: #0078d4;
  font-family: 'Open Sans', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.message {
  font-size: 24px;
  margin-bottom: 50px;
  font-family: 'Open Sans', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.homeLink {
  font-size: 18px;
  color: #0078d4;
  text-decoration: none;
  border: 2px solid #0078d4;
  padding: 10px 20px;
  border-radius: 5px;
  font-family: 'Open Sans', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
}

.homeLink:hover {
  background-color: #0078d4;
  color: #ffffff;
  text-decoration: none;
}