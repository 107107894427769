.footer {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    color: #333;
    padding: 40px 20px;
    text-align: left;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
}

.column {
    flex: 1;
    min-width: 200px;
    margin: 0 20px;
}

.logo {
    width: 190px;
    height: auto;
    margin-top: 20px;
}

h2 {
    margin-bottom: 10px;
    margin-top: 22px;
    font-weight: normal;
    color: #333;
}

.address {
    margin: 5px 0;
    font-size: 16px;
}

.socialLinks {
    display: flex;
    align-items: center;
}

.socialLinks a {
    color: #333;
    text-decoration: none;
}

.bottom {
    text-align: center;
    margin-top: 40px;
    margin-bottom: -35px;
}

.bottomText {
    font-size: 16px;
}

.link {
    color: #333;
    text-decoration: none;
    margin: 0 5px;
}

.link:hover {
    text-decoration: none;
}

.address a {
    color: inherit;
    text-decoration: none;
}

.address a:hover {
    text-decoration: none;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .column {
        margin: 10px 0;
        text-align: center;
    }

    .logo {
        width: 140px;
        height: auto;
    }

    h2 {
        margin-top: 10px;
    }

    .socialLinks {
        justify-content: center;
        margin-bottom: -20px;
    }
}