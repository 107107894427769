.announcementBar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #0078d4;
  color: white;
  z-index: 1100;
  padding: 10px 30px;
  text-align: center;
  height: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.learnMoreBtn {
  background: none;
  border: none;
  color: white;
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
  font-weight: bold;
}

.closeBtn {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  line-height: 1;
  padding: 0;
  margin-left: 10px;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.modalContent {
  position: relative;
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 700px;
  width: 90%;
}

.modalContent h2 {
  margin-bottom: 1rem;
  color: #0078d4;
}

.modalContent p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.logos {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}

.logo {
  max-width: 150px;
  max-height: 80px;
  margin: 0 20px;
}


@media (max-width: 768px) {
  .announcementBar {
    padding: 10px 15px;
    height: 20px;
  }

  .content {
    justify-content: center;
    flex-grow: 1;
  }

  .learnMoreBtn {
    margin-top: 0;
    margin-left: 10px;
  }

  .closeBtn {
    margin-left: 10px;
  }

  .modalContent {
    padding: 20px;
    width: 90%;
    max-width: 90%;
    max-width: 90%;
    box-sizing: border-box;
  }

  .modalContent p {
    font-size: 14px;
    margin-bottom: 1rem;
    line-height: 1.4;
  }

  .modalContent h2 {
    font-size: 18px;
    margin-bottom: 1rem;
  }

  .modalContent .closeModalIcon {
    top: 8px !important;
    right: 8px !important;
  }
}

@media (orientation: landscape) {
  .modalContent {
    width: 70%;
  }
}

.closeModalIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: #2e2e2e;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  z-index: 10000;
  transition: transform 0.3s ease, color 0.3s ease;
}

.closeModalIcon:hover {
  color: #2e2e2e;
  transform: rotate(90deg);
}