.container {
  max-width: 800px;
  margin: 60px auto;
  padding: 20px;
  color: #333;
}

.heading {
  font-size: 2rem;
  font-weight: 600;
  color: #0078d4;
  margin-bottom: 30px;
  margin-top: -70px;
}

.subheading {
  font-size: 1.25rem;
  font-weight: 500;
  color: #333;
  margin: 20px 0 10px;
}

p {
  line-height: 1.6rem;
  margin-bottom: 15px;
  font-size: 1rem;
}

.bulletPoints {
  list-style-type: disc;
  padding-left: 20px;
  margin: 10px 0;
}

.bulletPoints li {
  line-height: 1.6rem;
  margin: 5px 0;
  color: #333;
  font-size: 1rem;
}

a {
  color: #0078d4;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .container {
    padding: 15px;
  }

  .heading {
    font-size: 1.8rem;
  }

  .subheading {
    font-size: 1.1rem;
  }

  .bulletPoints li {
    font-size: 1rem;
  }
}