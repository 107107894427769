.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Open Sans', Tahoma, Geneva, Verdana, sans-serif;
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
  -webkit-font-smoothing: antialiased;
}

.main {
  flex: 1;
  padding: 2rem;
  transform: translateY(20px);
  animation: slideUp 0.6s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.page-enter {
  opacity: 0;
  transform: translateY(20px);
}

.page-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}