.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  padding: 10px 30px;
  background-color: rgba(255, 255, 255, 0.9);
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
  align-items: center;
}

.header.atTop {
  box-shadow: none;
}

.header:not(.atTop) {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.visible {
  transform: translateY(0);
}

.hidden {
  transform: translateY(-100%);
}

.logo {
  height: 38px;
  transition: filter 0.3s ease;
  filter: grayscale(20%);
}

.logoLink {
  text-decoration: none;
  margin-left: -10px;
}

.nav {
  display: flex;
  align-items: center;
}

.navList {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
}

.navList li {
  margin-right: 30px;
}

.navList li:last-child {
  margin-right: 0;
}

.navLink {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 500;
  font-size: 1rem;
  color: rgb(95, 95, 95);
  text-decoration: none !important;
  padding: 5px 0;
  border-bottom: 2px solid transparent;
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

.navLink:hover {
  color: #0078d4;
  border-bottom: 2px solid #0078d4;
}

.activeLink {
  color: #0078d4;
  border-bottom: 2px solid #0078d4;
}

.quoteButton {
  background-color: transparent;
  color: #0078d4;
  padding: 8px 20px;
  border: 2px solid #0078d4;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.quoteButton:hover {
  background-color: #0078d4;
  color: white;
}

.quoteButton.activeLink {
  background-color: #005a9e;
  color: white;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  width: 30px;
  height: 20px;
  justify-content: space-between;
  margin-right: -10px;
  margin-top: -5px;
}

.hamburger span {
  width: 100%;
  height: 3px;
  background-color: rgb(95, 95, 95);
  transition: all 0.3s ease;
}

.hamburger.open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -6px);
}

@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .nav {
    display: none;
  }

  .navOpen .nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.98);
    height: calc(100vh - 70px);
    padding-top: 20px;
    overflow-y: auto;
  }

  .navOpen .navList {
    flex-direction: column;
    align-items: center;
  }

  .navOpen .navList li {
    margin: 15px 0;
  }

  .header {
    padding: 10px 15px;
  }

  .logoLink {
    margin-left: -5px;
  }

  .hamburger {
    margin-right: -5px;
  }
}

.dropdown {
  position: relative;
}

.dropdown .navLink {
  cursor: pointer;
}

.dropdownContent {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  min-width: 200px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 0;
  z-index: 1001;
  list-style: none;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.dropdownContent li {
  margin: 0 !important;
}

.dropdownContent .navLink {
  padding: 8px 16px;
  display: block;
  border-bottom: none;
}

.dropdownContent .navLink:hover {
  background-color: #f5f5f5;
  border-bottom: none;
}

.externalIcon {
  font-size: 0.8em;
  margin-left: 4px;
}

.announcementSpacing {
  top: 20px;
}

@media (max-width: 768px) {
  .dropdownContent {
    position: static;
    box-shadow: none;
    background-color: transparent;
    display: block;
    padding: 0;
    width: 100%;
    text-align: center;
  }

  .dropdown {
    width: 100%;
  }

  .dropdown>.navLink {
    font-weight: bold;
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }

  .dropdownContent .navLink {
    padding: 8px 0;
    width: 100%;
    text-align: center;
  }
}

.catalogButton {
  background-color: transparent;
  color: #0078d4;
  padding: 8px 20px;
  border: 2px solid #0078d4;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.catalogButton:hover {
  background-color: #0078d4;
  color: white;
}

.catalogOptions {
  padding: 2rem;
  text-align: center;
}

.catalogOptions h2 {
  margin-bottom: 1.5rem;
}

.catalogGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  max-width: 800px;
  margin: 0 auto;
}

.catalogCard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background-color: #f5f5f5;
  border-radius: 8px;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.2s;
}

.catalogCard:hover {
  background-color: #e5e5e5;
}

@media (max-width: 768px) {
  .catalogOptions {
    padding: 2rem;
    text-align: center;
    max-width: 90%;
    margin: 0 auto;
  }

  .catalogGrid {
    max-width: 90%;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .catalogOptions {
    padding: 2rem;
    text-align: center;
    max-width: 90%;
    margin: 0 auto;
  }

  .catalogGrid {
    max-width: 90%;
    margin: 0 auto;
  }
}