.container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
}

.enhancedHero {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(135deg, #ffffff 0%, #f0f0f0 100%);
  color: #333;
  padding: 0 5%;
  position: relative;
  overflow: hidden;
}

.heroContent {
  z-index: 2;
  max-width: 800px;
}

.heroContent h1 {
  font-size: clamp(2.5rem, 5vw, 4rem);
  margin-bottom: 1rem;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: 1px;
}

.heroContent p {
  font-size: clamp(1.2rem, 2.5vw, 2rem);
  line-height: 1.2;
  opacity: 0.8;
  margin-bottom: 0.5rem;
}

.heroSubtext {
  font-size: clamp(1rem, 1.5vw, 1.3rem);
  opacity: 0.6;
  font-weight: 300;
  line-height: 1.5;
}

.heroGraphic {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  opacity: 0.1;
  pointer-events: none;
}

.heroGraphic svg {
  width: 100%;
  height: 100%;
}

.main {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  line-height: 1.1;
}

.section {
  margin-bottom: 6rem;
}

.sectionTitle {
  font-size: clamp(2rem, 4vw, 3rem);
  margin-bottom: 1rem;
}

.sectionDescription {
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  width: 100%;
  margin-bottom: 3rem;
  line-height: 1.6;
  opacity: 0.8;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.imageContainer {
  aspect-ratio: 3/4;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.imageContainer:hover {
  transform: scale(1.05);
}

.imageButton {
  width: 100%;
  height: 100%;
  padding: 0;
  border: none;
  background: #747474;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 1rem;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease;
}

.imageDescription {
  color: white;
  font-size: 1rem;
  margin: 0;
  transform: translateY(20px);
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 0;
  font-weight: 200;
  letter-spacing: 0.8px;
  line-height: 1.6;
  text-align: center;
  padding: 0 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.imageButton:hover .imageDescription {
  transform: translateY(0);
  opacity: 1;
}

.imageButton:hover .imageOverlay {
  opacity: 1;
}

.ctaSection {
  text-align: center;
  padding: 4rem 2rem;
  background: #f7f7f7;
  border-radius: 12px;
  margin: 4rem 0;
}

.ctaSection h2 {
  font-size: clamp(1.8rem, 3vw, 2.5rem);
  margin-bottom: 2rem;
}

.ctaButton {
  background: #005a9e;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.ctaButton:hover {
  background: #004377;
}

.modal {
  position: fixed;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 9999;
  overflow-y: hidden;
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
}

.modalContent {
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  width: 100%;
  box-sizing: border-box;
}

.modalImage {
  max-width: 90vw;
  max-height: calc(90vh - 100px);
  object-fit: contain;
  border-radius: 8px;
  opacity: 0;
  transform: scale(0.95);
  animation: zoomIn 0.3s ease forwards;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: auto;
  height: auto;
}

.modalDescription {
  margin-top: 2rem;
  color: #333;
  text-align: center;
  width: 90%;
  max-width: 800px;
  opacity: 0;
  animation: fadeIn 0.3s ease 0.2s forwards;
}

.closeButton {
  position: fixed;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: #333;
  font-size: 2.5rem;
  cursor: pointer;
  z-index: 10000;
}

@media (max-width: 768px) {
  .enhancedHero {
    height: auto;
    min-height: 70vh;
    flex-direction: column;
    text-align: center;
    padding: 3rem 1.5rem;
    justify-content: center;
  }

  .heroGraphic {
    display: block;
    width: 100%;
    height: auto;
  }

  .heroContent {
    max-width: 100%;
  }

  .heroContent h1 {
    font-size: clamp(2rem, 4vw, 3rem);
    margin-bottom: 1.5rem;
  }

  .heroContent p {
    font-size: clamp(1rem, 2vw, 1.5rem);
    margin-bottom: 1rem;
  }

  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
  }

  .closeButton {
    top: 0.5rem;
    right: 0.5rem;
  }

  .main {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .enhancedHero {
    min-height: 60vh;
    padding: 2rem 1rem;
  }

  .heroGraphic {
    display: block;
    width: 100%;
    height: auto;
  }

  .gallery {
    grid-template-columns: 1fr;
  }

  .modalContent {
    padding: 1rem;
  }

  .modalImage {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.navButton {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.1);
  border: none;
  color: #333;
  font-size: 3rem;
  padding: 1rem 0.5rem;
  cursor: pointer;
  z-index: 10000;
}

.navButton:hover {
  background: rgba(0, 0, 0, 0.2);
}

.prevButton {
  left: 1rem;
}

.nextButton {
  right: 1rem;
}

body.modal-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.imageButton.touched .imageOverlay,
.imageButton:hover .imageOverlay {
  opacity: 1;
}

.imageButton.touched .imageDescription,
.imageButton:hover .imageDescription {
  transform: translateY(0);
  opacity: 1;
}

@media (hover: none) {
  .imageOverlay {
    transition: opacity 0.2s ease;
  }

  .imageDescription {
    transition: all 0.2s ease;
  }
}

.seeMore {
  font-size: 1.2rem;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.seeMore:hover {
  color: #ffffff;
}