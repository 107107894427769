.aboutContainer {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Open Sans', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.aboutImage {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
}

.title {
  font-size: 2rem;
  font-weight: 400;
  color: #333;
  text-align: left;
  margin-bottom: 20px;
}

.description {
  font-size: 1.2rem;
  color: #333;
  text-align: left;
  margin-bottom: 20px;
  line-height: 1.6;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sectionTitle {
  font-size: 1.6rem;
  font-weight: 300;
  color: #0078D4;
  margin-bottom: 10px;
}

.sectionText {
  font-size: 1.2rem;
  color: #333;
  line-height: 1.6;
}

.commitmentQuote {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.5;
  color: #4b4b4b;
  font-style: italic;
  text-align: center;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  margin-top: 15px;
}

.seriesContent {
  font-size: 1.2rem;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .aboutContainer {
    padding: 8px;
  }

  .title {
    font-size: 1.5rem;
    text-align: center;
  }

  .description {
    font-size: 1rem;
    text-align: left;
  }

  .sectionTitle {
    font-size: 1.2rem;
    text-align: center;
  }

  .sectionText {
    font-size: 1rem;
  }

  .commitmentQuote {
    font-size: 1.2rem;
    padding: 15px;
    text-align: center;
  }
}