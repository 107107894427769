.contactContainer {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 60px;
  font-family: 'Open Sans', Tahoma, Geneva, Verdana, sans-serif;
}

.title {
  font-size: 2rem;
  font-weight: 400;
  color: #333;
  margin-bottom: 15px;
  line-height: 1.5;
}

.contentContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}

.infoContainer {
  flex: 1;
  min-width: 300px;
  text-align: left;
  margin-left: 60px;
}

.mapContainer {
  flex: 1;
  min-width: 300px;
  margin-right: 20px;
}

.info {
  font-size: 1.1rem;
  margin: 10px 0;
  color: #333;
}

.text {
  font-size: 1.1rem;
  font-weight: 400;
  margin-bottom: 60px;
  color: #333;
}

.info strong {
  font-weight: 500;
}

a {
  color: #333;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none;
}

@media (max-width: 768px) {
  .contentContainer {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }

  .mapContainer,
  .infoContainer {
    min-width: 100%;
    margin: 0 0 20px 0;
  }

  .title {
    line-height: 1.2 !important;
  }
}

@media (max-width: 480px) {
  .contentContainer {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }

  .mapContainer,
  .infoContainer {
    min-width: 100%;
    margin: 0 0 20px 0;
  }

  .title {
    line-height: 1.2 !important;
  }
}