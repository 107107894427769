.container {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}

.heroSection {
  height: calc(100vh - 110px);
  width: 100%;
  background-image: url('../assets/images/hero.webp');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-dark);
  text-align: center;
  transition: transform 0.5s ease, opacity 0.5s ease;
  margin-bottom: 110px;
}

.heroContent {
  position: relative;
  padding: 20px;
  transform: translateY(-35px);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 800px;
}

.heroContent::before {
  content: '';
  position: absolute;
  top: 58%;
  left: 50%;
  width: 110%;
  height: 110%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  backdrop-filter: blur(8px);
  z-index: -1;
}

.heroContent h1 {
  font-size: 3.5rem;
  font-weight: 300;
  margin-bottom: 0px;
  color: var(--text-dark);
  line-height: 1.2;
}

.heroContent p {
  font-size: 1.5rem;
  font-weight: 200;
  color: var(--text-dark);
  line-height: 1.3;
}

.heroContent a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #005a9e;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 300;
  font-size: 18px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 15px;
}

.heroContent a:hover {
  background-color: #004377;
}

.whoWeAreSection {
  text-align: center;
  padding: 4rem 2rem;
  background: #f7f7f7;
  margin-bottom: 50px;
}

.missionSection {
  text-align: center;
  padding: 4rem 2rem;
  margin-bottom: 50px;
}

.sectionContent {
  max-width: 800px;
  margin: 0 auto;
}

.sectionContent h2 {
  font-size: 2.5rem;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  color: var(--text-dark);
}

.sectionContent p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: var(--text-dark);
}

.seriesSection {
  padding: 2rem 0;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.imageContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 60px;
  max-width: 1600px;
  width: 100%;
  padding: 0 20px;
}

.imageWrapper {
  position: relative;
  flex: 1;
  aspect-ratio: 2 / 3;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.imageWrapper:hover,
.imageWrapper:focus-within {
  transform: scale(1.02);
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: background-color 0.3s ease;
}

.imageWrapper:hover .imageOverlay,
.imageWrapper:focus-within .imageOverlay {
  background-color: rgba(0, 0, 0, 0.7);
}

.imageTitle {
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.1vw;
  font-weight: 400;
  line-height: 1.2;
  width: 80%;
  color: #fff;
  text-align: center;
  transition: all 0.3s ease;
}

.imageDescription {
  font-size: 1.3vw;
  font-weight: 300;
  text-align: center;
  max-width: 85%;
  opacity: 0;
  line-height: 1.5;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translateY(20px);
}

.imageWrapper:hover .imageTitle,
.imageWrapper:focus-within .imageTitle,
.imageWrapper:active .imageTitle {
  top: 22%;
  font-size: 1.9vw;
}

.imageWrapper:hover .imageDescription,
.imageWrapper:focus-within .imageDescription,
.imageWrapper:active .imageDescription {
  opacity: 1;
  transform: translateY(0);
}

.imageWrapper.active .imageOverlay {
  background-color: rgba(0, 0, 0, 0.7);
}

.imageWrapper.active .imageDescription {
  opacity: 1;
  transform: translateY(0);
  margin-top: 20px;
}

.ctaSection {
  text-align: center;
  margin: 2rem 0;
}

.ctaButton {
  background: #005a9e;
  color: white;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.ctaButton:hover {
  background: #004377;
}

@media (hover: none) and (pointer: coarse) {
  .imageWrapper:active .imageTitle {
    top: 20%;
    font-size: 1.8vw;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 3px;
  }

  .whoWeAreSection,
  .missionSection {
    padding: 2rem;
  }

  .sectionContent h2 {
    font-size: 2rem;
  }

  .sectionContent p {
    font-size: 1rem;
  }

  .seriesSection {
    margin-bottom: 30px;
  }

  .imageContainer {
    flex-direction: column;
    align-items: center;
  }

  .imageWrapper {
    width: 80vw;
    height: calc(80vw * 1.5);
  }

  .imageTitle {
    font-size: 7.5vw;
    transition: all 0.2s ease;
  }

  .imageWrapper:hover .imageTitle,
  .imageWrapper:focus-within .imageTitle,
  .imageWrapper:active .imageTitle {
    top: 10%;
    font-size: 6vw;
  }

  .imageWrapper.active .imageTitle {
    top: 10%;
    font-size: 5.5vw;
  }

  .imageDescription {
    font-size: 4vw;
    margin-top: 15px;
  }

  .heroContent {
    padding: 20px;
    max-width: 75%;
    margin: 0 auto;
  }

  .heroContent h1 {
    font-size: 2.7rem;
  }

  .heroContent::before {
    border-radius: 12px;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 2px;
  }

  .heroContent {
    padding: 20px;
    max-width: 75%;
    margin: 0 auto;
  }

  .imageWrapper {
    width: 90vw;
    height: calc(90vw * 1.5);
  }

  .imageTitle {
    font-size: 8.5vw;
  }

  .imageWrapper:hover .imageTitle,
  .imageWrapper:focus-within .imageTitle,
  .imageWrapper:active .imageTitle {
    top: 10%;
    font-size: 7.5vw;
  }

  .imageWrapper.active .imageTitle {
    top: 10%;
    font-size: 8vw;
  }

  .imageDescription {
    font-size: 5vw;
    margin-top: 15px;
  }
}

@media (orientation: portrait) {
  body {
    margin: 0;
    padding: 0;
  }

  .imageContainer {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
  }

  .heroContent {
    padding: 20px;
    max-width: 75%;
    margin: 0 auto;
  }

  .imageWrapper {
    width: 90vw;
    height: auto;
  }
}

.duradekSection {
  text-align: center;
  padding: 4rem 2rem;
  background: #f7f7f7;
  background-size: cover;
  margin-bottom: 50px;
}

.duradekCta {
  margin-top: 2rem;
}

.duradekButton {
  display: inline-block;
  color: #005a9e;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
  transition: color 0.2s ease;
}

.duradekButton:hover {
  color: #004377;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .duradekSection {
    padding: 2rem;
  }
}